import { inject } from '@angular/core';
import { createInjectable } from 'ngxtension/create-injectable';
import { signalSlice } from 'ngxtension/signal-slice';
import { catchError, EMPTY, map, tap } from 'rxjs';
import type { UserContextGetResponseType } from '@tig-dpqa-cloud/contract-backend-frontend';
import { HttpClient } from '@angular/common/http';
import { TenantService } from '../tenant/tenant.service';

export const UserContextService = createInjectable(
  () => {
    const http = inject(HttpClient);
    const tenantService = inject(TenantService);

    const initialState: {
      userPermissions: UserContextGetResponseType['user']['permissions'] | undefined;
      tenantConfig: UserContextGetResponseType['tenant'] | undefined;
      userProfile: Omit<UserContextGetResponseType['user'], 'permissions'> | undefined;
      dataplaneVersion: UserContextGetResponseType['dataplaneVersion'];
      possibleTenants: UserContextGetResponseType['possibleTenants'];
      isTenantOnboarded: UserContextGetResponseType['isTenantOnboarded'];
      credentials: UserContextGetResponseType['credentials'] | undefined;
    } = {
      userProfile: undefined,
      userPermissions: undefined,
      tenantConfig: undefined,
      dataplaneVersion: undefined,
      possibleTenants: [],
      isTenantOnboarded: undefined,
      credentials: undefined,
    };

    const userContext$ = http.get<UserContextGetResponseType>('/api/user/context').pipe(
      map((res) => ({
        userPermissions: res.user.permissions,
        tenantConfig: res.tenant,
        userProfile: {
          firstName: res.user.firstName,
          lastName: res.user.lastName,
          email: res.user.email,
        },
        dataplaneVersion: res.dataplaneVersion,
        possibleTenants: res.possibleTenants,
        isTenantOnboarded: res.isTenantOnboarded,
        credentials: res.credentials,
      })),
      tap(({ tenantConfig }) => (tenantService.currentTenant = tenantConfig?.tenantId)),
      catchError(() => EMPTY),
    );

    const state = signalSlice({
      initialState,
      sources: [userContext$],
    });

    return {
      userProfile: state.userProfile,
      userPermissions: state.userPermissions,
      tenantConfig: state.tenantConfig,
      dataplaneVersion: state.dataplaneVersion,
      possibleTenants: state.possibleTenants,
      isTenantOnboarded: state.isTenantOnboarded,
      credentials: state.credentials,
    };
  },
  { providedIn: 'root' },
);
